<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2 class="txtL">메모수정</h2>
				<a @click="memoDetail()" class="btnBack">메모 목록으로 이동</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- boxWhite -->
		<div class="boxWhite">
			<div class="inner">
				<!-- inputTable -->
				<div class="inputTable v2">
					<table>
						<caption>
							메모내용, 실별 오브제, 이미지 항목으로 구성된 메모수정 표입니다.
						</caption>
						<colgroup>
							<col style="width:110px" />
							<col style="width:auto" />
						</colgroup>
						<tbody>
							<tr class="fN">
								<th scope="row">메모내용 <span>*</span></th>
								<td>
									<textarea
										title="메모내용"
										placeholder="메모내용을 입력하세요."
										v-model="content"
										maxlength="1000"
										style="min-height: 150px;"
									></textarea>
								</td>
							</tr>
							<tr>
								<th scope="row">사용 위치</th>
								<td>
									<!-- attaArea -->
									<div class="attaArea">
										<p v-for="(location, index) in locations" :key="index">
											<span
												>{{ location.floor ? location.floor.name : '' }}
												{{ location.room ? ' &gt; ' + location.room.name : '' }}
												{{ location.objectPin ? ' &gt; ' + location.objectPin.objectName : '' }}</span
											><a class="btnDel" @click="deleteLocation(index)">삭제</a>
										</p>
									</div>
									<!--// attaArea -->
								</td>
							</tr>
							<tr>
								<th scope="row">위치 등록</th>
								<td>
									<div class="btnBox">
										<a class="btn btnWhite memoBtn" @click="onOpenLocation()">추가하기</a>
									</div>
								</td>
							</tr>
							<tr>
								<th scope="row">스케줄</th>
								<td>
									<div class="float">
										<span class="checkbox">
											<input
												type="checkbox"
												id="chk01"
												:checked="schedule != null"
												@click.prevent.stop
												@click="onOpenSchedule"
												style="cursor:pointer"
											/>
											<label for="chk01">{{ schedule ? schedule.name : null }}</label>
										</span>
										<span class="flex">{{
											schedule
												? `${$moment(schedule.beginDate).format('YYYY년 MM월 DD일')} ~ 
												${$moment(schedule.endDate).format('YYYY년 MM월 DD일')}`
												: null
										}}</span>
										<button type="button" class="btn ss" v-if="schedule" @click="onModifySchedule">수정</button>
									</div>
								</td>
							</tr>
							<tr>
								<th scope="row">프로젝트</th>
								<td>
									<span class="checkbox">
										<input type="checkbox" id="chk01" @click="onOpenProject" :checked="checkedValuesProject" />
										<label for="chk01"></label>
									</span>
									<span class="checkbox" v-if="checkedValuesProject">
										<input type="checkbox" id="chk02" @click="onOptionValueChk" :checked="checkedOptionValues" />
										<label for="chk02">전체</label>
									</span>
									<div class="flex" v-if="checkedValuesProject">
										<select title="선택" v-if="checkedOptionValues == true" v-model="projectId">
											<option :value="null">미선택</option>
											<option v-for="project in projects" :value="project.id" :key="project.id">
												{{ project.name + `  [${project.beginDate} ~ ${project.endDate}]` }}
											</option>
										</select>

										<select title="선택" v-if="checkedOptionValues == false" v-model="projectId">
											<option :value="null">미선택</option>
											<option v-for="project in projectsOrder" :value="project.id" :key="project.id">
												{{ project.name + `  [${project.beginDate} ~ ${project.endDate}]` }}
											</option>
										</select>

										<button type="button" class="btn ss" @click="onPrev()">신규</button>
									</div>
								</td>
							</tr>
							<tr>
								<th scope="row" class="mNone">이미지</th>
								<td>
									<!-- file -->
									<div class="file">
										<input
											type="file"
											title="이미지 업로드"
											required="required"
											id="image"
											@change="onImageUpload"
											accept="image/*"
											multiple
										/>
										<span>이미지 업로드</span>
									</div>
									<span class="checkbox" v-if="checkedVal()">
										<input type="checkbox" id="chk" v-model="checkedValues" />
										<label for="chk01">원본으로 올리기</label>
									</span>
									<!--// file -->
									<!-- imgFileList -->
									<ul class="imgFileList v2">
										<li v-for="(image, index) in images" :key="index">
											<div class="imgArea">
												<img :src="imagesPreview[index]" alt="" />
											</div>
											<a @click="onImageDelete(index, image.fileId)" class="btnDel">삭제</a>
										</li>
									</ul>
									<!--// imgFileList -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--// inputTable -->
				<!-- inputTable -->
				<div class="inputTable">
					<table>
						<caption>
							첨부파일 항목으로 구성된 메모수정 표입니다.
						</caption>
						<colgroup>
							<col style="width:110px" />
							<col style="width:auto" />
						</colgroup>
						<tbody>
							<tr>
								<th scope="row" class="mNone">첨부파일</th>
								<td>
									<!-- attaArea -->
									<div class="attaArea">
										<p v-for="(file, index) in files" :key="index">
											<a>{{ filesPreview[index] }}</a
											><a @click="onFileDelete(index, file.fileId)" class="btnDel">삭제</a>
										</p>
									</div>
									<!--// attaArea -->
									<!-- file -->
									<div class="file">
										<input
											type="file"
											title="첨부파일 업로드"
											required="required"
											id="file"
											@change="onFileUpload"
											multiple
										/>
										<span>첨부파일 업로드</span>
									</div>
									<!--// file -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--// inputTable -->
			</div>
		</div>
		<!--// boxWhite -->
		<!-- inner -->
		<div class="inner">
			<!-- btnBottom -->
			<div class="btnBottom">
				<button type="button" class="btn" @click="onUpdate()" :disabled="!saveBtn">수정</button>
			</div>
			<!--// btnBottom -->
		</div>
		<!--// inner -->
		<PopUpLocation
			ref="popUpLocation"
			@selectLocation="getLocation"
			:propsBuildingId="buildingId"
			:propsFloorId="floorId"
			:propsRoomId="roomId"
			:propsObjectPinId="objectPinId"
		/>
		<PopUpSchedule ref="popUpSchedule" @setSchedule="getSchedule" />
	</div>
	<!--// container -->
</template>
<script>
import { showSpinner, hideSpinner, isImage, hideMenu, overFileSize } from '@/utils/cmm';
import memo from '@/api/memo';
import file from '@/api/file';
import memoFile from '@/api/memoFile';
import schedule from '@/api/schedule';
import PopUpLocation from '../popup/popUpLocation';
import PopUpSchedule from '../popup/popUpSchedule';
import project from '@/api/project';

// import objectpin from '@/api/objectpin';
// import room from '@/api/room';

export default {
	components: { PopUpLocation, PopUpSchedule },
	created() {
		if (!this.$route.params.memoId) {
			this.$router.push({ name: 'buildingList' });
			return false;
		}
		hideMenu();
		this.onGet();
	},
	computed: {
		saveBtn: {
			get() {
				return this.content.length > 0;
			},
			set() {},
		},
	},
	data() {
		return {
			id: this.$route.params.memoId,
			buildingId: null,
			floorId: null,
			roomId: null,
			objectPinId: null,
			content: '',
			images: [],
			imagesPreview: [],
			files: [],
			filesPreview: [],
			deleteImages: [],
			deleteFiles: [],
			roomName: null,
			checkedValues: false,
			checkedOptionValues: false,
			locations: [],
			scheduleId: null,
			schedule: null,
			projects: [],
			projectsOrder: [],
			projectRes: null,
			projectId: null,
			projectIdUrl: null,
			projectIdTemp: null,
			name: null,
			checkedValuesProject: null,
		};
	},
	methods: {
		async onGet() {
			try {
				showSpinner();
				let res = await memo.getOne(this.id);
				this.title = res.title;
				this.locations = res.locations ? res.locations : [];
				this.content = res.content;
				this.buildingId = res.buildingId;
				this.floorId = res.floorId;
				this.roomId = res.roomId;
				this.objectPinId = res.objectPinId;
				this.scheduleId = res.scheduleId;
				// res.projectId 가 값이 없을때 undefined가 나와 값이 없으면 null로 바꿔준다.
				this.projectId = res.projectId ? res.projectId : null;
				this.projectIdTemp = res.projectId ? res.projectId : null;
				this.projectIdUrl = this.$route.params.projectId;

				// 빌딩에 해당되는 프로젝트 리스트를 불러옵니다.
				let projectParams = { buildingId: this.buildingId };
				let projectRes = await project.getProjectStartList(projectParams);
				this.projectIdUrl = this.$route.params.projectId;
				this.name = this.$route.params.name;

				// total ( 등록된 projectList)가 없으면 미수행
				if (projectRes.total != 0) {
					projectRes.data.forEach(p => this.projects.push(p));

					projectRes.data.forEach(p => {
						if (p.dateOrder == 1) this.projectsOrder.push(p);
					});
				}
				// 메모에 프로젝트가 등록되어 있으면 프로젝트 시작과 동시에 체크박스, 라벨 표시
				if (this.projectId) {
					this.checkedValuesProject = true;

					let proRes = await project.getOne(this.projectId);
					this.projectRes = proRes;
					if (proRes.dateOrder == 1) {
						this.checkedOptionValues = false;
					} else {
						this.checkedOptionValues = true;
					}
				}
				let params = {
					id: this.id,
					type: 'I',
				};
				let imageRes = await memoFile.get(params);
				if (imageRes.total > 0) {
					this.images = imageRes.data;
					// 메모에 저장된 이미지를 불러와서 먼저 보여준다.
					this.images.forEach(i => this.imagesPreview.push(`/api/file/${i.fileId}`));
				}
				params.type = 'F';
				let fileRes = await memoFile.get(params);
				if (fileRes.total > 0) {
					this.files = fileRes.data;
					// 메모에 저장된 파일을 불러와서 먼저 보여준다.
					this.files.forEach(f => this.filesPreview.push(f.fileName));
				}

				if (this.scheduleId) {
					this.schedule = await schedule.getOne(this.scheduleId);
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async onImageUpload({ target }) {
			if (!(target && target.files && target.files.length)) return;
			const files = [];
			files.push.apply(files, target.files);
			document.getElementById('image').value = '';
			for (let i in files) {
				if (!isImage(files[i])) {
					return this.$alert(`이미지는 png, jpg, jpeg, bmp의 확장자 파일만 올려주세요.`);
				}
			}
			if (overFileSize(this.images, files, 0)) {
				return this.$alert(`이미지 파일의 총합은 100MB를 초과할 수 없습니다.`);
			}
			this.imagesPreview = [];
			this.images = this.images.concat(files);
			for (var index in this.images) {
				if (!this.images[index].fileId) {
					// 이미지 배열에 파일아이디가 없으면, 새로 추가한 이미지
					this.imagesPreview[index] = URL.createObjectURL(this.images[index]);
				} else {
					// 이미지 배열에 파일아이디가 존재하면, 기존 이미지
					this.imagesPreview[index] = `/file/${this.images[index].fileId}`;
				}
			}
		},
		async onImageDelete(id, fileId) {
			this.images.splice(id, 1);
			this.imagesPreview = [];
			// 이미지 배열 삭제 요청시, 파일아이디가 존재하면 지울 이미지 배열에 추가한다.
			if (fileId) {
				this.deleteImages.push(fileId);
			}
			for (var index in this.images) {
				if (!this.images[index].fileId) {
					this.imagesPreview[index] = URL.createObjectURL(this.images[index]);
				} else {
					this.imagesPreview[index] = `/file/${this.images[index].fileId}`;
				}
			}
		},
		async onFileUpload({ target }) {
			if (!(target && target.files && target.files.length)) return;
			const files = [];
			files.push.apply(files, target.files);
			document.getElementById('file').value = '';
			if (overFileSize(this.images, files, 0)) {
				return this.$alert(`첨부 파일의 총합은 100MB를 초과할 수 없습니다.`);
			}
			this.filesPreview = [];
			this.files = this.files.concat(files);
			for (var index in this.files) {
				if (!this.files[index].fileId) {
					// 파일 배열에 파일아이디가 없으면, 새로 추가한 파일
					this.filesPreview[index] = this.files[index].name;
				} else {
					// 파일 배열에 파일아이디가 존재하면, 기존 파일
					this.filesPreview[index] = this.files[index].fileName;
				}
			}
		},
		async onFileDelete(id, fileId) {
			this.files.splice(id, 1);
			this.filesPreview = [];
			// 파일 배열 삭제 요청시, 파일아이디가 존재하면 지울 파일 배열에 추가한다.
			this.deleteFiles.push(fileId);
			for (var index in this.files) {
				if (!this.files[index].fileId) {
					this.filesPreview[index] = this.files[index].name;
				} else {
					this.filesPreview[index] = this.files[index].fileName;
				}
			}
		},
		async onUpdate() {
			let locations = [];
			if (this.locations) {
				this.locations.forEach(l => {
					let location = {};
					location.floorId = null;
					if (l.floor) {
						location.floorId = l.floor.id;
					}
					location.roomId = null;
					if (l.room) {
						location.roomId = l.room.id;
					}
					location.objectPinId = null;
					if (l.objectPin) {
						location.objectPinId = l.objectPin.id;
					}
					locations.push(location);
				});
			}
			let params = {
				title: this.title,
				content: this.content,
				id: this.id,
				locations: locations,
				scheduleId: this.scheduleId,
				schedule: this.schedule,
				projectId: this.projectId,
			};

			try {
				showSpinner();
				// 1. 지울 이미지나 파일이 존재하면 삭제 요청
				if (this.deleteImages.length > 0) {
					this.deleteImages.forEach(async di => await memoFile.remove(di));
				}
				if (this.deleteFiles.length > 0) {
					this.deleteFiles.forEach(async df => await memoFile.remove(df));
				}
				// 2. 새롭게 추가할 이미지나 파일이 존재하면 새로 추가 요청
				let newImages = this.images.filter(i => !i.fileId);
				if (newImages.length > 0) {
					let imageRes = await file.upload(newImages, this.checkedValues);
					params.imageInfos = imageRes.data;
				}
				let newFiles = this.files.filter(f => !f.fileId);
				if (newFiles.length > 0) {
					let fileRes = await file.upload(newFiles, true);
					params.fileInfos = fileRes.data;
				}
				// 3. 메모 등록
				await memo.update(params);

				// 프로젝트에서 왔을경우 링크 바꿔주기
				this.projectIdUrl = this.projectId;
				this.memoDetail();
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		memoDetail() {
			//this.$router.go(-1);
			this.$router.push({
				name: 'memoDetail',
				params: {
					id: this.id,
					buildingId: this.$route.params.buildingId,
					reqUrl: this.$route.params.reqUrl,
					projectId: this.projectIdUrl,
					name: this.name,
				},
			});
		},
		onOpenLocation() {
			this.$refs.popUpLocation.onOpenLocation();
		},
		getLocation(location) {
			if (!this.locations) {
				this.locations.push(location);
				return;
			}
			let flag = true;
			for (let i = 0; i < this.locations.length; i++) {
				let lFloorId = null;
				if (this.locations[i].floor) {
					lFloorId = this.locations[i].floor.id;
				}
				let lRoomId = null;
				if (this.locations[i].room) {
					lRoomId = this.locations[i].room.id;
				}
				let lObjctPinId = null;
				if (this.locations[i].objectPin) {
					lObjctPinId = this.locations[i].objectPin.id;
				}

				let locationFloorId = null;
				if (location.floor) {
					locationFloorId = location.floor.id;
				}
				let locationRoomId = null;
				if (location.room) {
					locationRoomId = location.room.id;
				}
				let locationObjctPinId = null;
				if (location.objectPin) {
					locationObjctPinId = location.objectPin.id;
				}
				if (lFloorId == locationFloorId && lRoomId == locationRoomId && lObjctPinId == locationObjctPinId) {
					flag = false;
					break;
				}
			}
			if (flag == true) {
				this.locations.push(location);
			} else {
				this.$alert('동일한 위치가 이미 추가되어있습니다.');
			}
		},
		deleteLocation(index) {
			this.locations.splice(index, 1);
		},
		async onOpenSchedule() {
			if (this.schedule) {
				if (await this.$confirm('스케줄을 해제 하시겠습니까?', '스케줄 안내')) {
					this.schedule = null;
					return;
				}
			} else {
				this.$refs.popUpSchedule.onOpenSchedule(null);
			}
		},
		async onModifySchedule() {
			this.$refs.popUpSchedule.onOpenSchedule(this.schedule);
		},
		getSchedule(schedule) {
			this.schedule = schedule;
		},
		setIntervalType(intervalType) {
			let interval = '매년';
			switch (intervalType) {
				case 'Y':
					interval = '매년';
					break;
				case 'M':
					interval = '매월';
					break;
				case 'W':
					interval = '매주';
					break;
				case 'D':
					interval = '지정일';
					break;
			}
			return interval;
		},
		async onOpenProject() {
			// 프로젝트를 해제 또는 값이 없으면 모든값을 null fasle 처리한다. projectIdTemp를 미리 저장해 다시 체크할때 불러오게 했음.
			if (this.checkedValuesProject) {
				if (await this.$confirm('프로젝트를 해제 하시겠습니까?', '프로젝트 안내')) {
					this.checkedValuesProject = null;
					this.checkedOptionValues = false;
					this.projectId = null;
					return;
				}
			} else {
				// 프로젝트가 존재하면 프로젝트 라벨을 표시해준다.
				this.checkedValuesProject = true;

				// 오늘 날짜에 해당하는 프로젝트가 있는지 체크한다.
				if (this.projectRes && this.projectRes.dateOrder == 1) {
					// 프로젝트를 해제 하고 다시 체크 했을떄 자동으로 미리 저장한 값을 강제로 넣어준다.
					// checkedOptionValues가 false 이고, false이면 dateOrder값이 1인 리스트를 불러온다.
					this.projectId = this.projectIdTemp;
				} else {
					//(오늘 날짜에 해당하는 프로젝트가 없는 경우)
					//  프로젝트가 존재하지 않는 경우 체크
					if (this.projectRes == null) {
						this.checkedOptionValues = false;
						this.projectId = null;
					} else {
						// 프로젝트가 존재하고 지난 날짜의 프로젝트를 표시할 경우 true 처리 하여 전체보여주기 상태로 한다.
						this.checkedOptionValues = true;
						this.projectId = this.projectIdTemp;
					}
				}
			}
		},
		async onPrev() {
			if (
				!(await this.$confirm(
					'현재 작업 중인 데이터가 취소됩니다.\r\n프로젝트로 이동 하시겠습니까?',
					'프로젝트 이동 안내',
				))
			) {
				return;
			}
			this.$router.push({ name: 'projectRegist', params: { buildingId: this.buildingId } });
		},
		checkedVal() {
			return !(Array.isArray(this.images) && this.images == 0);
		},
		onOptionValueChk() {
			// checkedOptionValues true 경우
			if (this.checkedOptionValues) {
				// 체크를 해제하므로 false 처리
				this.checkedOptionValues = false;

				// dateOrder (현재 날짜에 해당하는 프로젝트가 있나 체크)
				if (this.projectRes && this.projectRes.dateOrder == 1) {
					this.projectId = this.projectIdTemp;
				} else {
					this.projectId = null;
				}
			} else {
				this.checkedOptionValues = true;
				this.projectId = this.projectIdTemp;
			}
		},
	},
};
</script>
